<template>
  <v-dialog
      v-model="value"
      v-on:click:outside="$emit('cancel')"
  >
    <v-card>
      <v-card-title class="headline" style="padding:0">
        <v-toolbar>
          <v-toolbar-title>
            Edit Hours Worked
          </v-toolbar-title>
        </v-toolbar>
      </v-card-title>
      <v-card-text style="overflow:scroll; max-height: 300px">
        <!--
            Debug: {{ dialogEvent.id }}
        -->
        <v-container fluid>
          <v-row>
            <span style="font-size: large; margin: auto">Status: {{ dialogEvent.status }}</span>
            <v-spacer />
            <transition-menu
              :event="dialogEvent"
              v-on:submit="$emit('submit', dialogEvent)"
              v-on:delete="$emit('delete', dialogEvent)"
              />
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="3" style="white-space: nowrap; padding-top: 0; padding-bottom: 0">
              <v-row>
                <span style = "margin-top:20px; margin-left:10px;">
                  From
                </span>
                <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="dialogEventDate"
                        v-bind="attrs"
                        v-on="on"
                        style="max-width:80px; margin-left: 10px"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                      v-model="dialogEventDate"
                      @input="dateMenu = false"
                      no-title
                      show-current
                  >
                  </v-date-picker>
                </v-menu>
                <v-combobox
                    dense
                    style="max-width:80px; margin-top:20px; margin-left:10px;"
                    :items="dialogEventStartTimeOptions"
                    v-model="dialogEventStartTime"
                    :append-icon="null"
                    id="dialogStartTime"
                >
                </v-combobox>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" md="3" :style="{'white-space': 'nowrap', 'padding-top': 0, 'padding-bottom':0, 'margin-top': $vuetify.breakpoint.smAndUp ? '-12px' : 'auto'}">
              <v-row>
              <span style = "margin-top:20px; margin-left:10px;">
                To
              </span>
                <v-combobox
                    dense
                    style="max-width:80px; margin-top:20px; margin-left:10px;"
                    :items="dialogEventEndTimeOptions"
                    v-model="dialogEventEndTime"
                    :append-icon="null"
                    id="dialogEndTime"
                >
                </v-combobox>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-textarea
                v-model="dialogEventInternal.details"
                label="Details"
            >
            </v-textarea>
          </v-row>
        </v-container>
        <v-timeline align-top dense>
          <!-- TODO: make this dynamic using the history of the item -->
          <v-timeline-item color="accent"><i><small>{{ formatTimeRelativeToNow(dialogEventInternal.created) }}</small></i> <b>Created</b></v-timeline-item>
          <v-timeline-item color="accent" v-if="dialogEventInternal.submitted"><i><small>{{
              formatTimeRelativeToNow(dialogEventInternal.submitted)
            }}</small></i> <b>Submitted</b> </v-timeline-item>
        </v-timeline>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="cancelDialog">
          Cancel
        </v-btn>
        <v-btn text @click="completeDialog">
          Done
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash'
import * as dt from '@/dateTools'
import TransitionMenu from "@/components/TransitionMenu";

export default {
  name: "EditEventDialog",
  components: {
    TransitionMenu
  },
  computed: {
  },
  mounted: function() {
    // protect against editing the object un-intentionally / allow cancel
    this.dialogEventInternal = _.cloneDeep(this.dialogEvent)
    this.dialogEventStartTimeOptions = dt.generateTimeOptions(dt.dayStart(new Date()), false, true, 15)
    this.dialogEventEndTimeOptions = dt.generateTimeOptions(this.dialogEventInternal.start, true, false, 30)
    this.dialogEventStartTime = dt.formatTime(this.dialogEventInternal.start)
    this.dialogEventEndTime = dt.formatTime(this.dialogEventInternal.end)
    this.dialogEventDurationMinutes = (this.dialogEventInternal.end - this.dialogEventInternal.start) / 60 * 1000
    this.dialogEventDate = dt.getDate(this.dialogEventInternal.start)
  },
  data: () => ({
    dialogEventInternal: {},
    dialogEventDate: null,
    dialogEventStartTime: null,
    dialogEventStartTimeOptions: null,
    dialogEventEndTime: null,
    dialogEventEndTimeOptions: [],
    dialogEventDurationMinutes: null,
    dialogReady: false,
    dateMenu: false,
  }),
  props: {
    value: {
      default: false,
      type: Boolean,
    },
    dialogEvent: {
      default: () => {},
      type: Object,
    },
  },
  methods: {
    cancelDialog: function() {
      this.$emit('cancel')
    },
    completeDialog: function() {
      this.$emit('save', this.dialogEventInternal)
    },
    formatTimeRelativeToNow(epochTime) {
      return dt.formatTimeRelativeToNow(epochTime)
    },
  }
}
</script>

<style scoped>

</style>
