function rnd(a,b) {
    return Math.floor((b-a+1) * Math.random()) + a
}

export function rndElement(arr) {
    return arr[rnd(0, arr.length - 1)]
}

export function newID() {
    return rnd(1, 100000)
}

export const colors = ['#2196F3', '#3F51B5', '#673AB7', '#00BCD4', '#4CAF50', '#FF9800', '#757575']

export function generateEvents({startDate, endDate}) {

    // creates a handful of random events
    const events = []

    const min = new Date(`${startDate}T00:00:00`).getTime()
    const max = new Date(`${endDate}T23:59:59`).getTime()
    const days = (max-min) / 86400000
    const eventCount = rnd(days, days + 20)

    for (let i = 0; i < eventCount; i++) {
        const timed = true
        const firstTimestamp = rnd(min, max)
        const secondTimestamp = rnd(2, timed ? 8 : 288) * 900000
        const start = firstTimestamp - (firstTimestamp % 900000 )
        const end = start + secondTimestamp
        const id = newID()

        events.push({
            id: id,
            status: "New",
            name: "Hours Worked",
            color: rndElement(colors),
            start,
            end,
            timed,
            created: Date.now(),
        })
    }

    return events
}
