export function getDate(epoch) {
    console.log("getDate")
    const rawDate = new Date(epoch)
    // Create an array with the current month, day and time
    const date = [ rawDate.getFullYear(), rawDate.getMonth() + 1, rawDate.getDate() ];

    date[1] = (date[1] < 10) ? "0" + date[1] : date[1]
    date[2] = (date[2] < 10) ? "0" + date[2] : date[2]

    return date.join("-")
}

//export function getDayTime(epochTime) {
//    return epochTime % (24 * 60 * 60 * 1000)
//}

export function dayStart(epochTime) {
    const tmp = new Date(epochTime)
    //return epochTime - getDayTime(epochTime) + tmp.getTimezoneOffset() * 60 * 1000
    const start = new Date(tmp.getFullYear(), tmp.getMonth(), tmp.getDate())
    return start.getTime()
}

export function formatMonthDay(epochTime) {
    const tmp = new Date(epochTime)
    const month = tmp.getMonth()
    const date = tmp.getDate()

    return `${month + 1}/${date}`
}

export function dayEnd(epochTime) {
    const today = dayStart(epochTime)
    return today + 24 * 60 * 60 * 1000
}

export function formatTimeRelativeToNow(epochTime) {
    // https://stackoverflow.com/a/6109105/6570011

    const inputDate = new Date(epochTime);
    const nowDate = new Date();

    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30; // generally
    const msPerYear = msPerDay * 365;

    var plural = '';
    var count = 0;

    var elapsed = nowDate - inputDate;

    if ( elapsed < msPerMinute ) {
        count = Math.round(elapsed / 1000);
        if (count > 1) plural = 's'
        return count + ' second' + plural + ' ago';
    }

    else if ( elapsed < msPerHour ) {
        count = Math.round(elapsed / msPerMinute);
        if (count > 1) plural = 's'
        return count + ' minute' + plural + ' ago';
    }

    else if ( elapsed < msPerDay ) {
        count = Math.round(elapsed / msPerHour);
        if (count > 1) plural = 's'
        return count + ' hour' + plural + ' ago';
    }

    else if (elapsed < msPerMonth) {
        count = Math.round(elapsed / msPerDay);
        if (count > 1) plural = 's'
        return '~ ' + count + ' day' + plural + ' ago';
    }

    else if  ( elapsed < msPerYear ) {
        count = Math.round(elapsed / msPerMonth );
        if (count > 1) plural = 's'
        return '~ ' + count + ' month' + plural + ' ago';
    }

    else {
        count = Math.round(elapsed / msPerYear );
        if (count > 1) plural = 's'
        return '~ ' + count + ' year' + plural + ' ago';
    }
}

// export function formatDateTime(epochTime) {
//     // https://gist.github.com/hurjas/2660489
//     const localDate = new Date(epochTime)
//     const date = [ localDate.getMonth() + 1, localDate.getDate(), localDate.getFullYear() ]
//     var time = [ localDate.getHours(), localDate.getMinutes(), localDate.getSeconds() ]
//     const ampm = ( time[0] < 12 ) ? "AM" : "PM"
//
//     // convert hours to 0-12
//     time[0] = ( time[0] < 12 ) ? time[0] : time[0] - 12
//
//     // if 0, set to 12
//     time[0] = time[0] || 12
//
//     // if seconds / minutes are < 10, add a zero
//     for ( var i = 1; i < 3; i ++) {
//         if ( time[i] < 10 ) {
//             time[i] = "0" + time[i];
//         }
//     }
//
//     return date.join("/") + " " + time.join(":") + " " + ampm
// }

export function formatTime(epochTime) {
    var ampm = "am"
    var hr = null
    const localDate = new Date(epochTime)
    //const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
    const hour = localDate.getHours()
    const min = localDate.getMinutes()
    hr = hour
    if (hour >= 12) { ampm = "pm"; }
    if (hour > 12) { hr = hour - 12 }
    if (hour === 0) { hr = 12}
    return hr + ":" + (min < 10 ? "0" + min : min) + ampm
}

export function formatElapsedTime(start, end) {
    const diff = end - start
    const hourDiff = diff / (60 * 60 * 1000)
    const minDiff = (diff / (60 * 60 * 1000)) * 60
    var str = ""

    if (hourDiff >= 1) {
        str = hourDiff + " hr" + (hourDiff > 1 ? "s" : "")
    } else {
        // presuming hourDiff === 0
        str = minDiff + " min" + (minDiff > 1 ? "s" : "")
    }
    return str
}

export function roundTime(time, down = true) {
    const roundTo = 15 // minutes
    const roundDownTime = roundTo * 60 * 1000

    return down
        ? time - time % roundDownTime
        : time + (roundDownTime - (time % roundDownTime))
}
export function toTime(tms) {
    return new Date(tms.year, tms.month-1, tms.day, tms.hour, tms.minute).getTime()
}

export function generateTimeOptions(startEpoch = dayStart(new Date()), addDuration = false, includeStart = true, maxIter = 15) {
    var arr = []
    var i = startEpoch;
    // exclude the start
    if (!includeStart) i = i + (15 * 60 * 1000)

    var iterDiff = (15 * 60 * 1000) // 15 min by default
    var str = ""
    while (i < dayEnd(startEpoch)) {
        str = formatTime(i)
        if (addDuration) {
            str = str + " (" + formatElapsedTime(startEpoch, i) + ")"
        }
        arr.push(str)
        if (i - startEpoch >= 60 * 60 * 1000) iterDiff = (maxIter * 60 * 1000)
        i = i + iterDiff
    }
    return arr
}
