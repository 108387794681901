import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

import colors from 'vuetify/lib/util/colors';

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#FFFFFF',
                secondary: '#e0e0e0',
                accent: '#f6d85f',
            },
            dark: {
                primary: '#181A1B',
                secondary: colors.green.lighten3,
                accent: colors.green.accent1,
            }
        },
        dark: false,
    }
});
