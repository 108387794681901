import Vue from 'vue'
import {Mutation} from './types.js'
import * as et from '@/eventTools'
import _ from 'lodash'

export default {
    [Mutation.saveEventList]: function(state, payload) {
        Vue.set(state, 'eventList', payload)
    },
    [Mutation.updateEvent]: function(state, payload) {
        // TODO: once the API handles, this will be simpler
        if (!payload.id) {
            console.log('No id... adding a new event!')
            let newEvent = _.cloneDeep(payload)
            newEvent.id = et.newID()
            state.eventList.push(newEvent)
            return
        }
        var position = state.eventList.indexOf(
            // TODO - ensure there is only one...
            // we just grab the first...
            state.eventList.filter((elt) => {
                return elt.id === payload.id
            })[0]
        )
        var obj = state.eventList[position]
        // to avoid updating the calendar by re-fetching data...
        // cloneDeep does not update the calendar itself
        // (i.e. data is not bound both ways)
        // therefore: update the object in place
        for (const key in payload) {
            Vue.set(obj, key, payload[key])
        }
    },
    [Mutation.deleteEvent]: function(state, payload) {
        // TODO: once the API handles, this will be simpler
        if (payload.status === "Submitted") {
            // do nothing (in case selectedEvent goes awry)
            return
        }
        let position = state.eventList.indexOf(
            // TODO - ensure there is only one...
            // we just grab the first...
            state.eventList.filter((elt) => {
                return elt.id === payload.id
            })[0]
        )
        state.eventList.splice(position, 1)
    },
    [Mutation.createEvent]: function(state, payload) {
        state.eventList.push(payload)
    }
}
