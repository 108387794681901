<template>
  <v-container>
    <div style="text-align:center">
      <v-btn-toggle
          v-model="toggle"
          rounded
          mandatory
      >
        <v-btn small>
          List
        </v-btn>
        <v-btn small>
          Calendar
        </v-btn>
      </v-btn-toggle>
    </div>
    <timesheet-list v-if="toggle === 0"></timesheet-list>
    <timesheet v-if="toggle === 1"></timesheet>
  </v-container>
</template>

<script>
import Timesheet from '@/components/Timesheet.vue'
import TimesheetList from '@/components/TimesheetList.vue'

export default {
  name: 'TimesheetView',
  components: {
    Timesheet,
    TimesheetList,
  },
  data: () => ({
    toggle: 0,
  })
}
</script>
