export const Action = {
    fetchEventList: 'fetchEventList',
    updateEvent: 'updateEvent',
    deleteEvent: 'deleteEvent',
    createEvent: 'createEvent',
}

export const Mutation = {
    saveEventList: 'saveEventList',
    updateEvent: 'updateEvent',
    deleteEvent: 'deleteEvent',
    createEvent: 'createEvent',
}
