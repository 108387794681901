import {Action, Mutation} from './types.js'

import * as et from '@/eventTools'

export default {
    [Action.fetchEventList]: function({commit}, {startDate, endDate}) {
        console.log("vuex fetchEventList")
        console.log(startDate)
        console.log(endDate)
        let events = et.generateEvents({startDate, endDate})
        console.log(events)
        // sort descending order
        events = events.sort(
            (a,b) => b.start - a.start
        )
        commit(`${[Mutation.saveEventList]}`, events)
    },
    [Action.updateEvent]: function({commit}, {event}) {
        // TODO: call the API to update
        commit(`${[Mutation.updateEvent]}`, event)
    },
    [Action.deleteEvent]: function({commit}, {event}) {
        // TODO: call the API to delete
        commit(`${[Mutation.deleteEvent]}`, event)
    },
    [Action.createEvent]: function({commit}, {event}) {
        // TODO: call the API to create
        commit(`${[Mutation.createEvent]}`, event)
    }
}
