// from https://manage.auth0.com/dashboard/us/dev-benetist/applications/avKkWTgctUDKYuJJUC0SPxaP0z67rThM/quickstart
import { getInstance } from './index'

export const authGuard = (to, from, next) => {
    const authService = getInstance();

    const fn = () => {
        // If user is authenticaed, continue with the route
        if (authService.isAuthenticated) {
            return next()
        } else {
            return next('/start')
        }
    }

    // If loading has already finished, check our auth state using `fn()`
    if (!authService.loading) {
        return fn()
    }

    // Watch for the loading propery to change before we check isAuthenticated
    authService.$watch('loading', loading => {
        if (loading === false) {
            return fn();
        }
    })
}
