import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import store from './store'
import VueMobileDetection from 'vue-mobile-detection'

// import Auth0 config
import { domain, clientId } from '../auth_config.json'
import { Auth0Plugin } from './auth';

Vue.config.productionTip = false
Vue.use(VueMobileDetection)

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname
    )
  }
})

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
