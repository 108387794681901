<template>
  <v-container>
    <v-list three-line flat>
      <v-list-item-group
        >
        <template v-for="event in events">
          <v-hover :key="event.id" v-slot:default="{ hover }">
            <v-list-item :key="event.id" @click="editEvent(event)" :class="{ 'on-hover': hover , 'elevation-2': !hover, 'elevation-12': hover }">
              <v-list-item-content v-if="false">
                <v-list-item-title style="text-align:center">
                  {{ formatStart(event.start) }}
                  ({{ formatDuration(event.start, event.end) }})
                </v-list-item-title>
                <v-list-item-subtitle style="text-align:center">
                  {{ event.details }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content v-if="true">
                <v-list-item-title :style="{'font-size' : $vuetify.breakpoint.smAndUp ? 'large' : 'small'}">
                  <span v-if="$vuetify.breakpoint.smAndUp">{{ event.status }} : </span>
                  {{ formatStart(event.start) }}
                  ({{ formatDuration(event.start, event.end) }})
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ event.details }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <transition-menu
                    :event="event"
                    v-on:submit="submitEvent"
                    v-on:delete="deleteEvent"
                  />
              </v-list-item-action>
            </v-list-item>
            </v-hover>
          </template>

      </v-list-item-group>
      <v-fab-transition>
        <v-btn
          color="accent"
          absolute
          top
          right
          fab
          light
          @click="addEvent"
          :style="{'margin-top': '35px', 'margin-right': $vuetify.breakpoint.mobile ? '5px' : '15px'}"
          >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-list>
    <edit-event-dialog
        v-model="dialog"
        :dialog-event="dialogEvent"
        v-on:cancel="dialog = false"
        v-on:save="completeDialog"
        v-on:submit="submitEvent"
        v-on:delete="deleteEvent"
        :key="dialogEvent.id"
    />
  </v-container>
</template>

<script>
import * as dt from '@/dateTools'
import * as et from '@/eventTools'
import {Action} from '@/store/events/types.js'
import EditEventDialog from "@/components/EditEventDialog";
import TransitionMenu from '@/components/TransitionMenu'
import _ from 'lodash'

export default {
  name: "TimesheetList",
  components: {EditEventDialog, TransitionMenu},
  data: () => ({
    colors: ['#2196F3', '#3F51B5', '#673AB7', '#00BCD4', '#4CAF50', '#FF9800', '#757575'],
    names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],

    selectedItem: [],
    dialog: false,
    dialogEvent: {},
    defaultEvent: {
      id: null,
      status: "New",
      name: "Hours Worked",
      color: et.rndElement(et.colors),
      start: Date.now(),
      end: Date.now(),
      timed: true,
      created: Date.now(),
    }
  }),
  methods: {
    formatStart: function(epoch) {
      return dt.formatMonthDay(epoch) + ' ' + dt.formatTime(epoch)
    },
    formatDuration: function(start, end) {
      return dt.formatElapsedTime(start, end)
    },
    addEvent: function() {
      this.dialog = true
      this.dialogEvent = _.cloneDeep(this.defaultEvent)
    },
    editEvent: function(event) {
      this.dialog=true
      this.dialogEvent = event
    },
    completeDialog(event) {
      this.updateEvent(event)
      this.dialog = false
    },
    updateEvent(event) {
      this.$store.dispatch(`${[Action.updateEvent]}`, {event: event})
    },
    isMobile() {
      return this.$isMobile()
    },
    submitEvent(event) {
      event.status = "Submitted"
      event.submitted = Date.now()
      this.$store.dispatch(`${[Action.updateEvent]}`, {event: event})
    },
    deleteEvent(event) {
        this.$store.dispatch(`${[Action.deleteEvent]}`, {event: event})
        this.dialog = false
    },
  },
  computed: {
    events() {
      let raw = this.$store.state.events.eventList
      if (raw) {
        raw = raw.sort(
            (a,b) => b.start - a.start
        )
      }
      return raw
    }
  },
  mounted() {
    this.$store.dispatch(`${[Action.fetchEventList]}`, {startDate: '2021-01-01', endDate: '2021-01-16'})
    this.dialogEvent = _.cloneDeep(this.defaultEvent)
  }
}
</script>

<style scoped>
</style>
