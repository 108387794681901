<template>
  <div>
    <v-menu
        v-if="manyOptions(event) && smallScreen()"
    >
      <template
          v-slot:activator="{on, attrs}"
      >
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>{{ getIcon(event) }}</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
            v-for="menuItem in menuItems(event)"
            :key="menuItem"
            @click="menuHandle(menuItem, event)"
        >
          {{menuItem}}
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu>
      <template v-slot:activator="{on, attrs}">
        <v-btn-toggle v-if="manyOptions(event) && !smallScreen()">
          <v-btn
              v-for="menuItem in menuItems(event)"
              :key="menuItem"
              @click="menuHandle(menuItem, event)"
              v-bind="attrs" v-on="on"
          >
            {{ menuItem }}
          </v-btn>
        </v-btn-toggle>
      </template>
    </v-menu>
    <v-btn icon v-if="!manyOptions(event)">
      <v-icon>
        {{ getIcon(event) }}
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "TransitionMenu",
  props: {
    event: {
      type: Object,
      required: true,
    }
  },
  methods: {
    getIcon(event) {
      if (event.status === 'Submitted') {
        return 'mdi-lock-outline'
      } else if (event.status === 'Approved') {
        return 'mdi-checkbox-marked-circle-outline'
      } else if (event.status === 'Rejected') {
        return 'mdi-alert-circle-outline'
      } else {
        return 'mdi-dots-vertical'
      }
    },
    manyOptions(event) {
      return !(event.status === 'Submitted' || event.status === 'Approved');
    },
    smallScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isMenu(event) {
      const manyOptions = this.manyOptions(event)
      const smallScreen = this.smallScreen()
      return manyOptions && smallScreen
    },
    menuHandle(menuItem, event) {
      if (menuItem === 'Submit') {
        this.$emit('submit', event)
      } else if (menuItem === 'Delete') {
        this.$emit('delete', event)
      }
    },
    menuItems(event) {
      if (event.status === 'Submitted') {
        return []
      } else if (event.status === 'New') {
        return [
          'Submit',
          'Delete'
        ]
      }
    }
  }
}
</script>

<style scoped>

</style>
