<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
    >
      <a
        href="/#/">
        <v-img
            :src="require('./assets/logo.webp')"
            contain
            height="55"
            max-width="70"
            class="logo"
            style="margin-top:10px"
        />
      </a>
      <v-spacer></v-spacer>

      <v-scale-transition v-if="false">
        <h1 :style="{'white-space': 'nowrap', 'font-size': $vuetify.breakpoint.xsOnly ? 'medium' : 'x-large'}">
          Timesheet Demo
        </h1>
      </v-scale-transition>

      <v-spacer />
      <v-scale-transition>
        <login-button
            :logged-in="authCheck"
            :show-sign-up="false"
            v-on:login="login"
            v-on:logout="logout"
            v-on:profile="$router.push('profile')"
        />
      </v-scale-transition>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import LoginButton from '@/components/LoginButton'

export default {
  name: 'App',

  components: {
    LoginButton
  },

  data: () => ({
    //
  }),

  methods: {
    login() {
      // TODO: how to do this at a non-root base path?
      this.$auth.loginWithRedirect()
      // Downside of popup: need to reload the page...
      //this.$auth.loginWithPopup()
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      })
    },
    authCheck() {
      return this.$auth.isAuthenticated
    },
  }
};
</script>
