<template>
    <v-row class="fill-height">
        <v-col>
            <v-sheet height="75">
                <v-toolbar flat>
                    <v-btn
                        outlined
                        class="mr-4"
                        color="grey darken-2"
                        @click="setToday"
                    >
                        Today
                    </v-btn>
                    <v-btn
                        fab
                        text
                        small
                        color="grey darken-2"
                        @click="prev"
                    >
                        <v-icon small>
                            mdi-chevron-left
                        </v-icon>
                    </v-btn>
                    <v-btn
                        fab
                        text
                        small
                        color="grey darken-2"
                        @click="next"
                    >
                        <v-icon small>
                            mdi-chevron-right
                        </v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="$refs.calendar">
                        {{ $refs.calendar.title }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-menu
                        bottom
                        right
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                outlined
                                color="grey darken-2"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <span>{{ typeToLabel[type] }}</span>
                                <v-icon right>
                                    mdi-menu-down
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="type = 'day'">
                                <v-list-item-title>Day</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'week'">
                                <v-list-item-title>Week</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'month'">
                                <v-list-item-title>Month</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = '4day'">
                                <v-list-item-title>4 days</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-toolbar>
            </v-sheet>
            <v-sheet height="100%">
                <v-calendar
                    ref="calendar"
                    v-model="value"
                    color="primary"
                    :type="type"
                    :events="events"
                    :event-color="getEventColor"
                    :event-ripple="true"
                    @click:event="showEvent"
                    @click:more="viewDay"
                    @click:date="viewDay"
                    @change="getEvents"
                    @mousedown:event="startDrag"
                    @mousedown:time="startTime"
                    @mousemove:time="mouseMove"
                    @mouseup:time="endDrag"
                    @mouseleave.native="cancelDrag"
                >
                    <template v-slot:event="{ event, timed, eventSummary }">
                        <div
                            class="v-event-draggable"
                            v-html="eventSummary()"
                        >
                        </div>
                        <div
                            v-if="timed"
                            class="v-event-drag-bottom"
                            @mousedown.stop="extendBottom(event)"
                        >
                        </div>
                    </template>
                </v-calendar>
                <v-menu
                    v-model="selectedOpen"
                    :close-on-content-click="false"
                    :activator="selectedElement"
                    offset-x
                >
                    <v-card
                        color="grey lighten-4"
                        min-width="350px"
                        flat
                    >
                        <v-toolbar
                            :color="getEventColor(selectedEvent)"
                            dark
                        >
                            <v-toolbar-title v-html="selectedEvent.status"></v-toolbar-title>
                            <!-- TODO: make this better on mobile -->
                            <v-spacer class="d-none d-sm-flex"></v-spacer>
                            <v-btn icon @click="deleteEvent" :disabled="selectedEvent.status === 'Submitted'">
                                <v-icon>mdi-trash-can-outline</v-icon>
                            </v-btn>
                            <v-btn icon>
                                <v-icon @click.stop="editEvent(selectedEvent)">mdi-pencil</v-icon>
                            </v-btn>
                            <!--
                            <v-btn icon>
                                <v-icon>mdi-send-lock</v-icon>
                            </v-btn>
                            -->
                        </v-toolbar>
                        <v-card-text>
                            <!--
                                Debug: <span v-html="selectedEvent.id"></span>
                                <br>
                            -->
                            <span v-html="selectedEvent.details"></span>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                text
                                color="secondary"
                                @click="selectedOpen = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn 
                                text
                                color="secondary"
                                @click="submitEvent(selectedEvent)"
                                :disabled="selectedEvent.status === 'Submitted'"
                            >
                                Submit
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
            </v-sheet>
        </v-col>
      <edit-event-dialog
          v-model="dialog"
          :dialog-event="dialogEvent"
          v-on:cancel="dialog = false"
          v-on:save="completeDialog"
          :key="dialogEvent.id"
      />
    </v-row>
</template>

<script>
import _ from "lodash"
import * as dt from '@/dateTools'
import * as et from '@/eventTools'
import EditEventDialog from "@/components/EditEventDialog";
import {Action} from '@/store/events/types.js'

export default {
    name: 'Timesheet',
  components: {EditEventDialog},
  data: () => ({
        value: '',
        type: '4day',
        typeToLabel: {
            day: 'Day',
            week: 'Week',
            month: 'Month',
            '4day': '4Days',
        },
        //events: [],
        colors: ['#2196F3', '#3F51B5', '#673AB7', '#00BCD4', '#4CAF50', '#FF9800', '#757575'],
        names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
        dragEvent: null,
        dragStart: null,
        createEvent: null,
        createStart: null,
        extendOriginal: null,

        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,

        dialogReady: false,
        dialog: false,
        dialogEvent: {},
        dialogEventDate: null,
        dialogEventStartTime: null,
        dialogEventStartTimeOptions: [],
        dialogEventEndTime: null,
        dialogEventEndTimeOptions: [],
        dialogEventDurationMinutes: null,
    }),
    methods: {
        setToday() {
            this.value = ''
        },
        prev() {
            this.$refs.calendar.prev()
        },
        next() {
            this.$refs.calendar.next()
        },
        viewDay({ date }) {
            this.value = date
            this.type = 'day'
        },


        showEvent ({ nativeEvent, event }) {
            // timeouts to delay the open by 10 ms?
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                setTimeout(() => {
                    this.selectedOpen = true
                }, 10)
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                if ( event.id === this.selectedEvent.id ) {
                    // close the menu
                    return
                } else {
                    // clicked on a new event
                    setTimeout(open, 10)
                }
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },

        cancelDialog() {
            this.dialog = false
        },

        completeDialog(event) {
            console.log("completeDialog")
            this.updateEvent(event)
            this.dialog = false
        },

        updateEvent(event) {
          this.$store.dispatch(`${[Action.updateEvent]}`, {event: event})
           // var position = this.events.indexOf(
           //     // TODO - ensure there is only one...
           //     // we just grab the first...
           //     this.events.filter((elt) => {
           //         return elt.id === event.id
           //     })[0]
           // )
           // var obj = this.events[position]
           // // to avoid updating the calendar by re-fetching data...
           // // cloneDeep does not update the calendar itself
           // // (i.e. data is not bound both ways)
           // // therefore: update the object in place
           // for (const key in event) {
           //     obj[key] = event[key]
           // }
        },

        deleteEvent () {
          this.$store.dispatch(`${[Action.deleteEvent]}`, {event: this.selectedEvent})
            //if (this.selectedEvent.status === "Submitted") {
            //    // do nothing (in case selectedEvent goes awry)
            //    return
            //}
            //var position = this.events.indexOf(
            //    // TODO - ensure there is only one...
            //    // we just grab the first...
            //    this.events.filter((elt) => {
            //        return elt.id === this.selectedEvent.id
            //    })[0]
            //)
            //this.events.splice(position, 1)
            this.selectedOpen = false
            this.dialog = false
        },

        startDrag({ event, timed }) {
          console.log('startDrag')
            if (event && timed) {
                this.dragEvent = event
                this.dragTime = null
                this.extendOriginal = null
            }
        },
        startTime(tms) {
            console.log("startTime")
            // do nothing if the click is to leave a modal
            if (this.selectedOpen) {
                return
            }
            const mouse = dt.toTime(tms)
            if (this.dragEvent && this.dragTime === null) {
                if (this.dragEvent.status === "Submitted") {
                    this.dragEvent = null
                    // disable dragging once submitted
                    return
                }
                const start = this.dragEvent.start
                this.dragTime = mouse - start
            } else {
                this.createStart = dt.roundTime(mouse)
                let myvar = {
                    id: et.newID(),
                    status: "New",
                    name: "Hours Worked",
                    color: et.rndElement(et.colors),
                    start: this.createStart,
                    end: this.createStart,
                    timed: true,
                    created: Date.now(),
                }

                this.$store.dispatch(`${[Action.createEvent]}`, {event: myvar})
                this.createEvent = myvar
                //this.events.push(this.createEvent)
                this.selectedEvent = myvar

                this.dialogReady = true
            }
        },
        extendBottom (event) {
            this.createEvent = event
            this.createStart = event.start
            this.extendOriginal = event.end
        },
        mouseMove(tms) {
            const mouse = dt.toTime(tms)

            if (this.dragEvent && this.dragTime !== null) {
                const start = this.dragEvent.start
                const end = this.dragEvent.end
                const duration = end - start
                const newStartTime = mouse - this.dragTime
                const newStart = dt.roundTime(newStartTime)
                const newEnd = newStart + duration

                this.dragEvent.start = newStart
                this.dragEvent.end = newEnd
            } else if (this.createEvent && this.createStart !== null) {
                const mouseRounded = dt.roundTime(mouse, false)
                const min = Math.min(mouseRounded, this.createStart)
                const max = Math.max(mouseRounded, this.createStart)

                this.createEvent.start = min
                this.createEvent.end = max
            }
        },
        endDrag() {
            console.log("endDrag")
            this.dragTime = null
            this.dragEvent = null
            this.createEvent = null
            this.createStart = null
            this.extendOriginal = null

            if (this.dialogReady) {
                // so dialog only fires on create
                this.dialogReady = false
                this.editEvent(this.selectedEvent)
            }
        },
        editEvent(event) {
            //this.dialogEventStartTimeOptions = dt.generateTimeOptions(dt.dayStart(new Date()), false, true, 15)
            //this.dialogEventEndTimeOptions = dt.generateTimeOptions(event.start, true, false, 30)
            //this.dialogEventStartTime = dt.formatTime(event.start)
            //this.dialogEventEndTime = dt.formatTime(event.end)
            //this.dialogEventDurationMinutes = (event.end - event.start) / 60 * 1000
            // protect against editing the object un-intentionally / allow cancel
            this.dialogEvent = _.cloneDeep(event)
            //this.dialogEventDate = dt.getDate(event.start)
            this.dialog = true
        },
        cancelDrag() {
            console.log("cancelDrag")
            if (this.createEvent) {
                if (this.extendOriginal) {
                    this.createEvent.end = this.extendOriginal
                } else {
                  // delete the item...?
                  // TODO: find a way to do this... with client ID...
                  //  const i = this.events.indexOf(this.createEvent)
                  //  if (i !== -1) {
                  //      this.events.splice(i, 1)
                  //  }
                  this.dialogReady = false
                }
            }
            this.createEvent = null
            this.createStart = null
            this.dragTime = null
            this.dragEvent = null
        },
        getEventColor(event) {
            //var color = event.color
            var color = this.colors[3]
            if (event.status === "New") {
                color = this.colors[0]
            } else if (event.status === "Submitted") {
                color = this.colors[1]
            }
            const rgb = parseInt(color.substring(1), 16)
            const r = (rgb >> 16) & 0xFF
            const g = (rgb >> 8) & 0xFF
            const b = (rgb >> 0) & 0xFF

            return event === this.dragEvent
                ? `rgba(${r}, ${g}, ${b}, 0.7)`
                : event === this.createEvent
                    ? `rgba(${r}, ${g}, ${b}, 0.7)`
                    : color
        },
        getEvents({ start, end }) {
          console.log('getEvents')
          this.$store.dispatch(`${[Action.fetchEventList]}`, {startDate: start.date, endDate: end.date})
          //this.events = et.generateEvents({start, end})
        },
        submitEvent(event) {
            event.status = "Submitted"
            event.submitted = Date.now()
            this.$store.dispatch(`${[Action.updateEvent]}`, {event: event})
        },
    },
  computed: {
    events() {
      return this.$store.state.events.eventList
    }
  },
  mounted () {
    this.$refs.calendar.checkChange()
  },
}
</script>

<style>
#dialogStartTime {
    text-align: center;
    padding-bottom: 4px;
}
#dialogEndTime {
    text-align: center;
    padding-bottom: 4px;
}
</style>
