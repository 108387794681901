import Vue from 'vue'
import VueRouter from 'vue-router'
import TimesheetView from '../views/TimesheetView.vue'
import { authGuard } from '../auth/authGuard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'TimesheetView',
    component: TimesheetView,
    beforeEnter: authGuard,
  },
  {
    path: '/start',
    name: 'Start',
    component: () => import(/* webpackChunkName: "start" */ '../views/Start.vue'),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
    beforeEnter: authGuard,
  }
]

const router = new VueRouter({
  routes
})

export default router
